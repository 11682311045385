import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../authContext";
import "../style.ver0.2.css";
import Dashnav from "../../sidebar/dashnav";
import resumeIcon from "../doc-icon.svg";


import MyVoice from "../myvoice.svg";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { coy } from 'react-syntax-highlighter/dist/esm/styles/prism';

const MockInterview = () => {

    const { isLoggedIn, user, sessionToken, fileSelect } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        const timer = setTimeout(() => {
            if (isLoggedIn === false) {
                navigate("/");
            }
            console.log(isLoggedIn);
        }, 2000); 
        return () => clearTimeout(timer);
    }, [isLoggedIn, navigate]);
    

    const [message, setMessage] = useState("");
    const [gptResponse, setGptResponse] = useState("");
    const [interviewQuestions, setInterviewQuestions] = useState([]);
    const [sessionID, setSessionID] = useState(""); // Use your session ID
    const [isListening, setIsListening] = useState(false);
    const [recognition, setRecognition] = useState(null);
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const [audioChunks, setAudioChunks] = useState([]);
    const [compLoading, setCompLoading] = useState(false);
    const [highlightactive, setHighlightactive] = useState(false);

    const toggleActive = () => {
        setHighlightactive(prevActive => !prevActive);
    };

    useEffect(() => {
        if (sessionToken && user) {
            loadSession();
        }
        console.log("token" + sessionToken);
    }, [sessionToken]);

    const loadSession = async () => {
        var text = "I'm going to start my mock interview and prepeare for it ?";
        if (text.trim() === "") return; // Prevent sending empty messages

        const payload = {
            transcribed_text: text,
            resume_load: true,
            email_id: user.email, // Use the logged-in user's email
            document_name: fileSelect,
            session_id: sessionID
        };

        try {
            const response = await fetch('https://us-east1-foursssolutions.cloudfunctions.net/audio_to_text_response_gpt', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });

            const data = await response.json();
            if (text !== data.response) { // Only add user question
                setInterviewQuestions([...interviewQuestions, { question: text, answer: data.response }]);
            }
            setGptResponse(data.response);
            setSessionID(data.session_id);
            // console.log(data.response);
            setMessage("");
        } catch (error) {
            console.error("Error:", error);
        }
    };
    

    const handleSend = async (text) => {
        if (text.trim() === "") return; // Prevent sending empty messages

        const payload = {
            transcribed_text: text,
            resume_load: true,
            email_id: user.email, // Use the logged-in user's email
            document_name: fileSelect,
            session_id: sessionID
        };

        try {
            const response = await fetch('https://us-east1-foursssolutions.cloudfunctions.net/audio_to_text_response_gpt', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });

            const data = await response.json();
            if (text !== data.response) { // Only add user question
                setInterviewQuestions([...interviewQuestions, { question: text, answer: data.response }]);
            }
            setGptResponse(data.response);
            setMessage("");
        } catch (error) {
            console.error("Error:", error);
        }
    };
    const startRecording = () => {
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then((stream) => {
                const recorder = new MediaRecorder(stream);
                setMediaRecorder(recorder);

                recorder.ondataavailable = (e) => {
                    setAudioChunks((prev) => [...prev, e.data]);
                };

                recorder.onstop = () => {
                    const audioBlob = new Blob(audioChunks, { type: 'audio/wav' });
                    const reader = new FileReader();
                    reader.readAsDataURL(audioBlob);
                    reader.onloadend = () => {
                        const base64data = reader.result;
                        localStorage.setItem('recordedAudio', base64data);
                    };
                };

                recorder.start();
            })
            .catch((err) => {
                console.error('Error accessing microphone: ', err);
            });
    };

    const stopRecording = () => {
        if (mediaRecorder) {
            mediaRecorder.stop();
            setMediaRecorder(null);
            setIsListening(false);
        }
    };

    const handleSpeech = () => {
        const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
        const recognitionInstance = new SpeechRecognition();

        recognitionInstance.continuous = true;

        recognitionInstance.onstart = () => {
            console.log('Voice recognition started. Speak into the microphone.');
            setIsListening(true);
        };

        recognitionInstance.onspeechend = () => {
            recognitionInstance.stop();
        };

        recognitionInstance.onresult = (event) => {
            const transcript = event.results[event.resultIndex][0].transcript.trim();
            setMessage(transcript);
            handleSend(transcript);
        };

        recognitionInstance.onerror = (event) => {
            console.error("Error occurred in recognition: ", event.error);
        };

        recognitionInstance.onend = () => {
            if (isListening) {
                recognitionInstance.start(); // Restart recognition after it ends
            }
        };

        setRecognition(recognitionInstance);
    };

    const toggleListening = () => {
        if (!recognition) {
            handleSpeech();
            if (!recognition) {
                console.error('Recognition is still not initialized.');
                return;
            }
        }

        if (isListening) {
            console.log('Stopping recognition and recording...');
            recognition.stop();
            setIsListening(false);
            stopRecording();
        } else {
            console.log('Starting recognition and recording...');
            recognition.start();
            setIsListening(true);
            setAudioChunks([]);
            startRecording();
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevent newline character in input field
            handleSend(message);
        }
        else if (e.key === 'ArrowDown') {
            toggleListening();
        }
    };

    const renderResponse = (response) => {
        const codeBlockRegex = /```(\w+)?\n([\s\S]*?)```/g;
        const parts = response.split(codeBlockRegex);

        return parts.map((part, index) => {
            if (index % 3 === 2) {
                const language = parts[index - 1] || 'text';
                return (
                    <SyntaxHighlighter key={index} language={language} style={coy}>
                        {part}
                    </SyntaxHighlighter>
                );
            }
            return <span key={index}>{part}</span>;
        });
    };
    useEffect(() => {
        setInterval(() => {
            setCompLoading(true);
        }, 400);
    }, []);

    return (
        <div className="main-dash row-flex">
            <Dashnav/>
            <div className={`content mock col-flex  ${compLoading===false ? "comp-loading": ""}`}>
                <div className={`row-flex row-dash ${highlightactive===false? "active":""} `}>
                    <div className="col-dash col-flex">
                        <div className="top-head">
                            <span>Interview Questions</span>
                        </div>
                        <div className="chats col-flex">
                            {interviewQuestions.map((qa, index) => (
                                <div key={index} className="chat-box col-flex user">
                                    <p>{qa.question}</p>
                                    <div className="chats-info row-flex">
                                        <span className="details">You</span>
                                        <span className="details">{new Date().toLocaleTimeString()}</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="col-dash">
                        <div className="top-head">
                            <span>Mock Interview GPT Helper</span>
                        </div>
                        <div className="chats">
                            {gptResponse && (
                                <div className="chat-box col-flex other">
                                    <div className="response-content">
                                        {renderResponse(gptResponse)}
                                    </div>
                                    <div className="chats-info row-flex">
                                        <span className="details">Interviewer</span>
                                        <span className="details">{new Date().toLocaleTimeString()}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="chat-inputs row-flex">
                            <input
                                type="text"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                onKeyDown={handleKeyDown}
                                placeholder="Message your gpt helper"
                            />
                            <button onClick={() => handleSend(message)}>Send</button>
                            <button id="speech-text" onClick={toggleListening}>
                                <img src={MyVoice} className={`${isListening ? "active" : ""}`} alt="voice-icon" />
                            </button>
                        </div>
                    </div>
                    <div className={`col-dash highlights ${highlightactive ? "active" : "" }`}>
                        <div className="top-head">
                            <span>HighLights</span>
                        </div>
                        <div className="chats col-flex">
                            <div className="chat-box col-flex highlight">
                                <p>Frontend Developer</p>
                            </div>
                            <div className="chat-box col-flex highlight">
                                <p>Js Developer</p>
                            </div>
                            <div className="chat-box col-flex highlight">
                                <p>React Developer</p>
                            </div>
                            <div className="chat-box col-flex highlight">
                                <p>Frontend Developer</p>
                            </div>
                        </div>
                    </div>
                    <div className="highlightOpen">
                        <button onClick={toggleActive}><img src={resumeIcon} alt="" /></button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MockInterview;
