import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate, } from "react-router-dom";
import { useAuth } from "../../authContext";
import "./style.ver0.2.css";
import "./documentManager/docStyle.ver0.1.css";
import ChatBot from "../chatbot/chat";
import FileList from "./documentManager/fileListDash";
import ResumeFileList from "./documentManager/resumeFile_list";
import Dashnav from "../sidebar/dashnav";
import UserIcon from "../profile/profile.png";


import jobScholar from "./Job-scholar.png";
import FileIcon from "./file-upload.svg";
import Resume from "./resume-icon.svg";
import Recording from "./recording.svg";
// import MyVoice from "./myvoice.svg";
import RecStart from "./rec-start.png";
import RecStop from "./rec-stop.png";
import RecVoice from "./voice-animver01.gif";
import Career from "./career.png";


const Dashboard = () => {
    const { isLoggedIn, user, fileSelect, sessionToken, logSessionId } = useAuth();
    const [filenames, setFilenames] = useState([]);
    const navigate = useNavigate();
    const [modalResume, setModalResume] = useState(false);
    const [file, setFile] = useState(null);
    const [message, setMessage] = useState("");
    const [chosenFiles, setChosenFiles] = useState("Choose Your Resume from below List of Files");
    const [modalVoice, setModalVoice] = useState(false);
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const [audioChunks, setAudioChunks] = useState([]);
    const [isrecording, setIsrecording] = useState(false);
    const [isrecorded, setIsrecorded] = useState(false);
    const [compLoading, setCompLoading] = useState(false);
    const [base64Audio, setBase64Audio] = useState(''); // State to store Base64 audio
    const [uploadMessage, setUploadMessage] = useState("");
    const [popupActive, setPopupActive] = useState("");
    const [skillsUpdateMessage, setSkillsUpdateMessage] = useState(""); // State for skills update message
    const [profileData, setProfileData] = useState({
        first_name: "",
        last_name: "",
        username: "",
        email: "",
        password: "",
        country: "",
        id: "",
        skills: "",
        gender: "",
        mobile_no: ""
    });
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        setInterval(() => {
            setCompLoading(true);
        }, 400);
    }, []);
    useEffect(() => {
        const timer = setTimeout(() => {
            if (isLoggedIn === false) {
                navigate("/");
            }
        }, 2000); 
        return () => clearTimeout(timer);
    }, [isLoggedIn, navigate]);
    
    function profilenavigator() {
        navigate('/profile');
    }

    const username = user != null ? user.email.split('@')[0] : "Your Name";

    const [currentDateTime, setCurrentDateTime] = useState(new Date());

    useEffect(() => {
        const timerId = setInterval(() => {
        setCurrentDateTime(new Date());
        }, 1000); // Update every second

        return () => clearInterval(timerId); // Clean up the interval on component unmount
    }, []);

    const formatDateTime = (date) => {
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
        return date.toLocaleDateString('en-US', options);
    };

    const openModResume = () => {
        setModalResume(true);
    }
    const closeModResume = () => {
        setModalResume(false);
    }
    const openModVoice = () => {
        // setModalVoice(true);
        navigate('/activejobs');
    }
    const closeModVoice = () => {
        setModalVoice(false);
    }
    

    //onclick mock interview
    function mockNavigator() {
        if (fileSelect == null) {
            setPopupActive("Open Load Resume and Choose Your Resume");
        } else {
            navigate('/dashboard/recording');
        }
    }
    function closePopup() {
        setPopupActive("");
        setSkillsUpdateMessage("");
    }
    const handleStart = () => {
        startRecording();
        rocordStart();
    };
    const handleStop = () => {
        stopRecording();
        rocordStop()
    };
    function rocordStart() {
        setIsrecording(true)
    }
    function rocordStop() {
        setIsrecording(false)
    }
    const startRecording = () => {
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then((stream) => {
                const recorder = new MediaRecorder(stream);
                setMediaRecorder(recorder);

                recorder.ondataavailable = (e) => {
                    setAudioChunks((prev) => [...prev, e.data]);
                };

                recorder.onstop = () => {
                    const audioBlob = new Blob(audioChunks, { type: 'audio/wav' });
                    const reader = new FileReader();
                    reader.readAsDataURL(audioBlob);
                    reader.onloadend = () => {
                        const base64data = reader.result;
                        setBase64Audio(base64data); // Store Base64 audio in state
                        localStorage.setItem('recordedAudio', base64data);
                    };
                };

                recorder.start();
            })
            .catch((err) => {
                console.error('Error accessing microphone: ', err);
            });
    };

    const stopRecording = () => {
        if (mediaRecorder) {
            mediaRecorder.stop();
            setMediaRecorder(null);
            setIsrecording(false);
            setIsrecorded(true);
        }
    };
    const uploadRecording = async () => {
        if (!base64Audio) {
            setMessage("No audio recording available.");
            setUploadMessage("No audio recording available.");
            return;
        }

        if (!user || !user.email) {
            setUploadMessage("User not logged in or user email not available.");
            return;
        }
        setUploadMessage("Uploading audio..."); // Message while uploading

        try {
            const response = await fetch('https://us-east1-foursssolutions.cloudfunctions.net/base64_audioclassification_gpt', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    audio_base64: base64Audio,
                    document_name: file ? file.name : "N/A",
                    email_id: user.email,
                    resume_load: true
                })
            });

            const data = await response.json();
            setMessage("Recording uploaded successfully.");
            setUploadMessage("Recording uploaded successfully."); // Success message
            console.log("Upload Response:", data);
        } catch (error) {
            console.error("Error:", error);
            setMessage("An error occurred while uploading the recording.");
        }
    };

    useEffect(() => {
        if (filenames=="") {
            setChosenFiles("Open Upload Documents and upload your files");
        } 
        else if (fileSelect == null) {
            setChosenFiles("Choose Your Resume from below List of Files");
            console.log(filenames=="");
        }else {
            setChosenFiles("Your chosen file is: " + fileSelect);
        }
        console.log("global session id" + logSessionId);
        
    }, [fileSelect, filenames]);

    const apiEndpointUserDetails = 'https://us-east1-foursssolutions.cloudfunctions.net/connect4sphere_database_api';

    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const queryObj = {
                    query: `SELECT id, first_name, last_name, username, email, password, country, skills, gender, created_at, storage_path, mobile_no FROM public.mockinterviewportal_users WHERE email='${user.email}'`
                };

                const response = await fetch(apiEndpointUserDetails, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(queryObj)
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }

                const data = await response.json();
                if (data.length > 0) {
                    setProfileData(data[0]);
                    console.log(data[0])
                    if (!data[0].skills) {
                        setSkillsUpdateMessage("Please complete your profile by adding your skills.");
                    }
                    else if (!data[0].gender) {
                        setSkillsUpdateMessage("Please complete your profile by adding your gender.");
                    }
                    else if (!data[0].mobile_no) {
                        setSkillsUpdateMessage("Please complete your profile by adding your mobile no.");
                    }
                }
                
                setLoading(false);
            } catch (error) {
                setError(error.message);
                setLoading(false);
            }
        };

        fetchProfileData();
    }, [user]);
    
    return (
        <div className="main-dash row-flex">
            <Dashnav/>
            <div className={`content col-flex  ${compLoading===false ? "comp-loading": ""}`}>
                <div className="user-dash row-flex">
                    <div className="user-col col-flex">
                        <div className="date"><span>{formatDateTime(currentDateTime)}</span></div>
                        <div className="greeting">
                            <h2 className="g-head">
                                Welcome back, {username}
                            </h2>
                            <span>Always stay updated in your Job Search portal</span>
                        </div>
                    </div>
                    <div className="user-col col-flex">
                        <img src={jobScholar} alt="scholarimg" className="bgImg" />
                    </div>
                    <div className="profile-button" onClick={profilenavigator}>
                        <img src={UserIcon} alt="icon" className="profile-icon" />
                    </div>
                </div>
                <div className="row-flex row-dash">
                    <div className="col-dash col-flex">
                        <div className="top-head">

                            <span>Documents Uploaded</span>
                        </div>
                        <div className="row-flex docs-row">
                            <div className="docslist">
                                
                                    
                                    <FileList filenames={filenames} setFilenames={setFilenames}/>
                                    
                                
                            </div>
                            <div className="height-divider"></div>
                            <div className="docsupload">
                                <Link to="/upload" className="docslink">
                                    <img src={FileIcon} alt="" className="docsIcon" />
                                    <span>Documents Upload</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-dash col-flex">
                        <div className="top-head">
                            <span>Unlock Your Potential</span>
                        </div>
                        <div className="row-flex interview-row">
                            <div className="docsupload" onClick={openModResume}>
                                    <img src={Resume} alt="" className="docsIcon" />
                                    <span>Load Resume</span>
                            </div>
                            <div className="height-divider"></div>
                            <div className="docsupload" onClick={mockNavigator}>
                                    <img src={Recording} alt="" className="docsIcon" />
                                    <span>Start Recording</span>
                            </div>
                            <div className="height-divider"></div>
                            <div className="docsupload" onClick={openModVoice}>
                                    {/* <img src={MyVoice} alt="" className="docsIcon" /> */}
                                    <img src={Career} alt="" className="docsIcon" />
                                    <span>Active Jobs</span>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <ChatBot/>             */}
            </div>
            {modalResume === true &&  (
                <>
                <div className="modal-resume">
                    <div className="col-flex inner">
                            <div className="col-flex upload-col">
                                <h3>Choose Your Resume</h3>
                            </div>
                            <ResumeFileList/>
                            <div className="uploaded-files col-flex">
                                <ul>
                                    <li><span className="file-name">{chosenFiles}</span></li>
                                </ul>
                            </div>
                            
                            
                            
                    </div>
                    <div className="close" onClick={closeModResume}>+</div>
                </div>
                </>
            )}
            {modalVoice === true &&  (
                <>
                <div className="modal-resume modal-voice">
                    <div className="col-flex inner">
                            <div className="col-flex upload-col">
                                <h3>Record Your Voice</h3>
                                <div className="voice-cont">
                                    <div className={`rec-start ${isrecording===false ? "active" : ""}`} onClick={handleStart}>
                                        <img src={RecStart} alt="recording-start" />
                                    </div>
                                    <div className={`rec-voice ${isrecording===true ? "active" : ""}`}>
                                        <img src={RecVoice} alt="loading" />
                                    </div>
                                    <div className={`rec-stop ${isrecording===true ? "active" : ""}`} onClick={handleStop}>
                                        <img src={RecStop} alt="recording-start" />
                                    </div>
                                </div>
                                <button id="upload-recording" className={`${isrecorded===true ? "active" : ""}`}  onClick={uploadRecording}>Upload</button>
                                {uploadMessage && <p className="uploading-stages">{uploadMessage}</p>} 
                                
                            </div>
                            
                    </div>
                    <div className="close" onClick={closeModVoice}>+</div>
                </div>
                </>
            )}
            {popupActive !=="" && (
                <>
                    <div className="f-screenpoppup">
                        <div className="popup-cont">
                            <div className="close" onClick={closePopup}>+</div>
                            <h3>{popupActive}</h3>
                            {popupActive=="Open Load Resume and Choose Your Resume" && (
                            <div className="profile-button" onClick={openModResume}>
                                Choose Resume >
                            </div>
)}
                        </div>
                    </div>
                </>
            )}
            {skillsUpdateMessage !== "" && (
                <div className="f-screenpoppup">
                    <div className="popup-cont">
                        <div className="close" onClick={closePopup}>+</div>
                        <h3>{skillsUpdateMessage}</h3>
                        <div className="profile-button" onClick={profilenavigator}>
                            Open Your Profile >
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
export default Dashboard;