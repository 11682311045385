import React, { useState, useEffect } from 'react';
import { useAuth } from "../../../authContext";
import DocIcon from "../doc-icon.svg";

const apiEndpoint = 'https://us-east1-foursssolutions.cloudfunctions.net/connect4sphere_database_api';

const ResumeFileList = () => {
    const [filenames, setFilenames] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { user, sessionToken, fileSelect, setSelectedFile } = useAuth();

    function fileChoose(item) {
        setSelectedFile(item);
    }
    useEffect(() => {
    },[fileSelect])

    useEffect(() => {
        const fetchFilenames = async () => {
            try {
                // Construct the query object
                const queryObj = {
                    query: `SELECT file_name FROM public.mockinterviewportal_fileuploaddetails WHERE emailid='${user.email}'`
                };

                // Make a POST request to the API
                const response = await fetch(apiEndpoint, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(queryObj)
                });

                // Check if request was successful
                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }

                // Parse response data
                const data = await response.json();
                console.log(data); // Verify response structure and content

                // Update state with extracted filenames
                const extractedFilenames = data.map(item => ({
                    id: item.id,
                    name: item.file_name
                }));

                // Update state variables
                setFilenames(extractedFilenames);
                setLoading(false); // Update loading state
            } catch (error) {
                setError(error.message); // Update error state
                setLoading(false); // Update loading state
            }
        };
        if (sessionToken && user) {
            fetchFilenames();
        }
    }, [sessionToken, user]);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <>
            <div className="myresumes col-flex">
                <h3 className="head">Click on Your Specified Files</h3>
                <ul className="row-flex">
                    {filenames.map((filename, index) => (
                        <li key={index}>
                            <div className={`file row-flex ${fileSelect === filename.name? "active":""}`} id={index} onClick={() => fileChoose(filename.name)}>
                                <img src={DocIcon} alt="" className="thumb" />
                                <span className="file-name">{filename.name}</span>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </>
    );
};

export default ResumeFileList;
