import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from '../../authContext';
import LoginBg from "./login-bg.svg";
import "./style.css?0.1ver";

const LoginSignup = () => {
    const { isLoggedIn, login, logout, logSessionId, setLogSessionId } = useAuth();
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('tab1');
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [country, setCountry] = useState("USA");
    const [loginEmail, setLoginEmail] = useState("");
    const [loginPassword, setLoginPassword] = useState("");
    const [loginError, setLoginError] = useState("");
    const [signupMessage, setSignupMessage] = useState("");
    const [passwordVisible, setPasswordVisible] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (isLoggedIn === true) {
                navigate("/dashboard");
            }
            console.log(isLoggedIn);
        }, 100); 
        return () => clearTimeout(timer);
    }, [isLoggedIn, navigate]);

    const handleTabClick = (tab) => {
        setActiveTab(tab);
        setSignupMessage("");
        setLoginError("");
    };

    const handleSignup = async () => {
        const formData = {
            action: 'sign-up',
            first_name: firstName,
            last_name: lastName,
            username: username,
            email: email,
            password: password,
            country: country,
            app_name:'mockinterviewportal',
        };

        try {
            const response = await fetch('https://us-east1-foursssolutions.cloudfunctions.net/user_authentication_api', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Network response was not ok');
            }

            const data = await response.json();
            setSignupMessage("Your registration was successful. Try to login now.");
        } catch (error) {
            setSignupMessage(error.message.includes("email already exists") ? "You have an existing account." : "Signup Error: " + error.message);
        }
    };

    const handleLogin = async () => {
        const formData = {
            action: 'sign-in',
            username: loginEmail,
            password: loginPassword,
            app_name:'mockinterviewportal',
        };

        try {
            const response = await fetch('https://us-east1-foursssolutions.cloudfunctions.net/user_authentication_api', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Network response was not ok');
            }

            const data = await response.json();
            login(data); // Pass the entire user data to the login function
            navigate('/dashboard');  // Navigate to the dashboard
        } catch (error) {
            setLoginError(error.message);
        }
    };

    const handleLogout = () => {
        logout();  // Set the login state to false
        navigate('/');  // Navigate back to the login page
    };

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    return (
        <div className="login-signup">
            <div className="login-inner">
                <img src={LoginBg} className="login-bg-img" alt="login-bg" />
                <div className={`log-sign-frm ${activeTab === 'tab1' ? 'active' : ''}`} id='one'>
                    <h3 className="log-in-title">Login</h3>
                    <div className="log-in-form col-flex">
                        <div className="input col-flex">
                            <span className="head">Username</span>
                            <input
                                type="text"
                                value={loginEmail}
                                onChange={(e) => setLoginEmail(e.target.value)}
                                placeholder="Enter Username"
                                className={`log-in-input ${loginEmail ? 'bold' : ''}`}
                            />
                        </div>
                        <div className="input col-flex">
                            <span className="head">Password</span>
                            <div className="password-input-wrapper">
                                <input
                                    type={passwordVisible ? "text" : "password"}
                                    value={loginPassword}
                                    onChange={(e) => setLoginPassword(e.target.value)}
                                    placeholder="Password@123"
                                    className={`log-in-input ${loginPassword ? 'bold' : ''}`}
                                />
                                <button
                                    type="button"
                                    className="password-toggle"
                                    onClick={togglePasswordVisibility}
                                >
                                    {passwordVisible ? '👁️' : '👁️‍🗨️'}
                                </button>
                            </div>
                        </div>
                        <Link className="forgot">
                            Forgot Password
                        </Link>
                    </div>
                    {loginError && <p className="error-message">{loginError}</p>}
                    <div className="row-flex button-row">
                        <button className="login-button" onClick={handleLogin}>
                            LOG IN
                        </button>
                        <button className="login-button" onClick={() => handleTabClick('tab2')}>
                            SIGN UP
                        </button>
                    </div>
                </div>
                <div className={`log-sign-frm ${activeTab === 'tab2' ? 'active' : ''}`} id='two'>
                    <h3 className="log-in-title">Sign Up</h3>
                    <div className="row-flex input-row">
                        <div className="log-in-form col-flex">
                            <div className="input col-flex">
                                <span className="head">First Name</span>
                                <input
                                    type="text"
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                    placeholder="Your first name"
                                    className={`log-in-input ${firstName ? 'bold' : ''}`}
                                />
                            </div>
                            <div className="input col-flex">
                                <span className="head">Last Name</span>
                                <input
                                    type="text"
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                    placeholder="Your last name"
                                    className={`log-in-input ${lastName ? 'bold' : ''}`}
                                />
                            </div>
                            <div className="input col-flex">
                                <span className="head">Username</span>
                                <input
                                    type="text"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value.replace(/\s+/g, ''))}
                                    placeholder="Username"
                                    className={`log-in-input ${username ? 'bold' : ''}`}
                                />
                            </div>
                        </div>
                        <div className="log-in-form col-flex">
                            <div className="input col-flex">
                                <span className="head">Password</span>
                                <div className="password-input-wrapper">
                                    <input
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        type={passwordVisible ? "text" : "password"}
                                        placeholder="secured password"
                                        className={`log-in-input ${password ? 'bold' : ''}`}
                                    />
                                    <button
                                        type="button"
                                        className="password-toggle"
                                        onClick={togglePasswordVisibility}
                                    >
                                        {passwordVisible ? '👁️' : '👁️‍🗨️'}
                                    </button>
                                </div>
                            </div>
                            <div className="input col-flex">
                                <span className="head">Email</span>
                                <input
                                    type="text"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="Email Id"
                                    className={`log-in-input ${email ? 'bold' : ''}`}
                                />
                            </div>
                            <div className="input col-flex">
                                <span className="head">Country</span>
                                <select
                                    value={country}
                                    onChange={(e) => setCountry(e.target.value)}
                                    className={`log-in-input ${country ? 'bold' : ''}`}
                                >
                                    <option value="USA">USA</option>
                                    <option value="India">India</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    {signupMessage && <p className="message">{signupMessage}</p>}
                    <div className="row-flex button-row">
                        <button className="login-button" onClick={handleSignup}>
                            SIGN UP
                        </button>
                        <button className="login-button" onClick={() => handleTabClick('tab1')}>
                            Go To Login
                        </button>
                    </div>
                </div>
            </div>
            {isLoggedIn && (
                <div>
                    <p>You are logged in.</p>
                    <button onClick={handleLogout}>Logout</button>
                </div>
            )}
        </div>
    );
};

export default LoginSignup;
