import React, { useState, useEffect } from 'react';
import DeleteIcons from "./delete-icon.png";
import { useAuth } from "../../../authContext";


const apiEndpoint = 'https://us-east1-foursssolutions.cloudfunctions.net/connect4sphere_database_api';

const FileList = () => {
    const [filenames, setFilenames] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { user, sessionToken, setSelectedFile } = useAuth();

    useEffect(() => {
        const fetchFilenames = async () => {
            try {
                // Construct the query object
                const queryObj = {
                    query: `SELECT id, file_name FROM public.mockinterviewportal_fileuploaddetails WHERE emailid='${user.email}'`
                };

                // Make a POST request to the API
                const response = await fetch(apiEndpoint, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(queryObj)
                });

                // Check if request was successful
                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }

                // Parse response data
                const data = await response.json();
                console.log(data); // Verify response structure and content

                // Update state with extracted filenames
                const extractedFilenames = data.map(item => ({
                    id: item.id,
                    name: item.file_name
                }));

                // Update state variables
                setFilenames(extractedFilenames);
                setLoading(false); // Update loading state
            } catch (error) {
                setError(error.message); // Update error state
                setLoading(false); // Update loading state
            }
        };
        if (sessionToken && user) {
            fetchFilenames();
        }
    }, [sessionToken]);

    const handleDelete = async (id) => {
        try {
            // Construct the query object for deletion
            const queryObj2 = {
                query: `DELETE FROM public.mockinterviewportal_fileuploaddetails WHERE id='${id}'`
            };

            const response = await fetch(apiEndpoint, {
                method: 'POST', // Use POST method to send the DELETE query
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(queryObj2)
            });

            // Check if deletion was successful
            if (!response.ok) {
                throw new Error('Failed to delete file');
            }

            // Remove the deleted filename from state
            setFilenames(prevFilenames => prevFilenames.filter(filename => filename.id !== id));
            setSelectedFile(null); // Reset selected file state when a file is deleted
        } catch (error) {
            setError(error.message);
        }
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div className="uploadedFiles">
            <h1>File List</h1>
            <ul className='col-flex'>
                {filenames.map((filename, index) => (
                    <li key={index} className="row-flex">{filename.name} <div className="delete" onClick={() => handleDelete(filename.id)}><img src={DeleteIcons} alt="" /></div></li>
                ))}
                
            </ul>
        </div>
    );
};

export default FileList;
