import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../authContext";
import FileList from "./fileList";
import "../style.ver0.2.css";
import "./docStyle.ver0.1.css";
import Dashnav from "../../sidebar/dashnav";
import JobIcon from "../documentManager/JobComponents/Jobs-icon.png"

import DocsBG from "./docsBgver01.png";

const DocumentUpload = () => {
    const [file, setFile] = useState(null);
    const [message, setMessage] = useState("");
    const [chosenFiles, setChosenFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(false); // Loading state
    const { isLoggedIn, user } = useAuth();
    const navigate = useNavigate();
    const [compLoading, setCompLoading] = useState(false);
    useEffect(() => {
        const timer = setTimeout(() => {
            if (isLoggedIn === false) {
                navigate("/");
            }
            console.log(isLoggedIn);
        }, 2000); 
        return () => clearTimeout(timer);
    }, [isLoggedIn, navigate]);

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
        setChosenFiles([...chosenFiles, selectedFile]);
        console.log(chosenFiles);
    };

    const handleUpload = async () => {
        if (!file) {
            setMessage("Please select a file to upload.");
            return;
        }

        if (!user || !user.email) {
            setMessage("User not logged in or user email not available.");
            return;
        }

        setIsLoading(true); // Set loading state to true

        const formData = new FormData();
        formData.append("file", file);
        formData.append("email_id", user.email);

        try {
            const response = await fetch('https://us-east1-foursssolutions.cloudfunctions.net/documents_upload_determine_type', {
                method: 'POST',
                body: formData
            });

            const data = await response.json();
            setMessage(data.message);
            console.log(user.email);

            // Insert record into the database
            const insertQuery = `
                INSERT INTO public.mockinterviewportal_fileuploaddetails 
                (file_name, file_path, file_extension, emailid, upload_datetime, api_response) 
                VALUES 
                ('${file.name}', 'docfiles/${user.email}/${file.name}', '${file.type}', '${user.email}', NOW(), '${data.message}');
            `;

            const insertResponse = await fetch('https://us-east1-foursssolutions.cloudfunctions.net/connect4sphere_database_api', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ query: insertQuery })
            });

            const insertData = await insertResponse.json();
            console.log("Insert Response:", insertData);

            setFile(null);  // Clear the file input
        } catch (error) {
            console.error("Error:", error);
            setMessage("An error occurred while uploading the file.");
        } finally {
            setIsLoading(false); // Set loading state to false
        }
    };
    useEffect(() => {
        setInterval(() => {
            setCompLoading(true);
        }, 400);
    }, []);

    function JobsPage() {
        navigate('/activeJobs');
    };

    return (
        <>
            <div className="main-dash row-flex">
                <Dashnav/>
                <div className={`content docs col-flex ${compLoading===false ? "comp-loading": ""}`}>
                    <div className="docs-tophead row-flex">
                        <div className="head-col col-flex">
                            <h2>Upload Your Documents</h2>
                        </div>
                        <div className="img-col col-flex">
                            <img src={DocsBG} alt="bg" className="bgImg" />
                        </div>
                    </div>
                    <div className="upload-container">
                        <div className="col-flex upload-col">
                            <h3>Upload Your File</h3>
                            <div className="upload-outer col-flex">
                                <div className="upload-file-box">
                                    <h3>Upload a File</h3>
                                    <div className="drag-text">Drag and drop or<br />click to upload</div>
                                </div>
                                <div className="inputContainer" role="button" aria-label="Upload a File Drag and drop files here">
                                    <input type="file" className="fileupload-input" onChange={handleFileChange} aria-hidden="true" />
                                </div>
                            </div>
                            <button onClick={handleUpload}>Upload</button>
                            {isLoading && <div className="loading-icon">Uploading...</div>} {/* Display loading indicator */}
                        </div>
                        {message && <p>{message}</p>}
                        {chosenFiles.length > 0 && (
                            <div className="uploaded-files col-flex">
                                <ul>
                                    {chosenFiles.map((fileName, index) => (
                                        <li key={index}>
                                            <span className="file-name">{fileName.name}</span>
                                            <div className="size">
                                                <span className="number">
                                                    {(fileName.size / 1048576).toFixed(2)}
                                                </span> mb
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                        <FileList/>
                    </div>
                    <div className="docs-tophead row-flex" onClick={JobsPage}>
                        <div className="head-col link col-flex">
                            <div className="row-flex">
                                <img src={JobIcon} alt="jobIcon" className="navicons"/>
                                <h2>Find Job Matches Tailored to Your Profile</h2>
                            </div>
                        </div>
                        <div className="img-col col-flex">
                            <span className="lnav">{'>>'}</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DocumentUpload;
