import React, { createContext, useState, useContext, useEffect } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [user, setUser] = useState(null);
    const [logSessionId, setLogSessionId] = useState("");
    const [sessionToken, setSessionToken] = useState(null);
    const [fileSelect, setFileSelect] = useState(null);  // New state for file selection
    const [isSessionClosed, setIsSessionClosed] = useState(false); // New state for session activity
    const inactivityTimeout = 15 * 60 * 1000; // 15 minutes in milliseconds


    useEffect(() => {
        const storedUser = localStorage.getItem('user');
        const storedToken = localStorage.getItem('sessionToken');
        const storedFileSelect = localStorage.getItem('fileSelect');

        if (storedUser && storedToken) {
            setUser(JSON.parse(storedUser));
            setSessionToken(storedToken);
            setIsLoggedIn(true);
        }

        if (storedFileSelect) {
            setFileSelect(JSON.parse(storedFileSelect));  // Initialize fileSelect from local storage
        }
    }, []);

    useEffect(() => {
        let inactivityTimer;

        const resetInactivityTimer = () => {
            clearTimeout(inactivityTimer);
            setIsSessionClosed(false);
            inactivityTimer = setTimeout(() => setIsSessionClosed(true), inactivityTimeout);
        };

        const handleActivity = () => {
            resetInactivityTimer();
        };

        // Add event listeners for various user activities
        window.addEventListener('mousemove', handleActivity);
        window.addEventListener('keydown', handleActivity);

        // Initialize the inactivity timer
        resetInactivityTimer();

        // Cleanup event listeners and timer on unmount
        return () => {
            clearTimeout(inactivityTimer);
            window.removeEventListener('mousemove', handleActivity);
            window.removeEventListener('keydown', handleActivity);
        };
    }, [isSessionClosed]);

    const login = (userData) => {
        setUser(userData);
        setSessionToken(userData.token); // Assuming the token is part of the userData
        setLogSessionId(userData.global_session_id);
        // console.log("session Id" + logSessionId);
        setIsLoggedIn(true);
        localStorage.setItem('user', JSON.stringify(userData));
        localStorage.setItem('sessionToken', userData.token);
    };

    const logout = () => {
        setUser(null);
        setSessionToken(null);
        setIsLoggedIn(false);
        setLogSessionId(null);
        console.log("logged out from session Id" + logSessionId);
        setFileSelect(null);
        localStorage.removeItem('user');
        localStorage.removeItem('sessionToken');
        localStorage.removeItem('fileSelect'); // Clear fileSelect from local storage on logout
    };

    const fetchWithAuth = async (url, options = {}) => {
        const headers = {
            ...options.headers,
            Authorization: `Bearer ${sessionToken}`,
        };
        const response = await fetch(url, {
            ...options,
            headers,
        });
        return response;
    };

    const setSelectedFile = (file) => {
        setFileSelect(file);
        if (file) {
            localStorage.setItem('fileSelect', JSON.stringify(file)); // Save fileSelect to local storage
        } else {
            localStorage.removeItem('fileSelect'); // Remove fileSelect from local storage if null
        }
    };

    return (
        <AuthContext.Provider value={{ isLoggedIn, user, sessionToken, login, logout, fetchWithAuth, fileSelect, setSelectedFile, isSessionClosed, setIsSessionClosed, logSessionId }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};
