import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../authContext";
import ProfessionalImage from "../dashboard/Graduation Cap.svg";
import DashIcon from "../dashboard/dash-icon.svg";
import DocsIcon from "../dashboard/documents.svg";
import EditProfile from "../dashboard/editProfile.png";
import SideIcon from "../svg-icon.svg"
import JobsIcon from "../dashboard/documentManager/JobComponents/Jobs-icon.png";
import LogoutIcon from "../Logout.png";
import ProfileIcon from "./profileicon.svg"

const Dashnav = () => {
    const [activeTab, setActiveTab] = useState("nav1");
    const { logout, fileSelect, isSessionClosed, setIsSessionClosed } = useAuth();
    const location = useLocation();
    const navigate = useNavigate();
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [Expandedsidebar, setExpandedsidebar] = useState(false);
    const [compLoading, setCompLoading] = useState(false);
    const [navPopup, setNavPopup] = useState("");
    const [sessionButton, setSessionButton] = useState(false);

    useEffect (()=> {
        if(isSessionClosed==true){
            setNavPopup("Session has been closed. Please log in again.");
            setSessionButton(true);
            const timer = setTimeout(() => {
                inactivityLogout();
            }, 5000 );
        }
    },[isSessionClosed, sessionButton])

    
    function continueSession() {
        setIsSessionClosed(false);
        setNavPopup("");
        setSessionButton(false);
        // console.log(isSessionClosed==false);
    }
    function inactivityLogout() {
        if(sessionButton===true) {
            console.log(sessionButton===false);
            logout();
        }
        else if(sessionButton===false) {
            console.log("Session is continued");
        }
    }


    const handleTabClick = (nav) => {
        setActiveTab(nav);
    };
    function expandSidebar() {
        if (isSidebarOpen ===false) {
            if (Expandedsidebar===false) {
                setExpandedsidebar(true);
            }
        }
        if (isSidebarOpen ===true) {
            setExpandedsidebar(false);
        }
    }
    function collapseSidebar() {
        if (Expandedsidebar ===true) {
            setExpandedsidebar(false);
        }
        if (isSidebarOpen ===true) {
            setExpandedsidebar(false);
        }
    }

    useEffect(() => {
        switch (location.pathname) {
            case "/dashboard":
                setActiveTab("nav1");
                setIsSidebarOpen(true);
                break;
            case "/dashboard/recording" :
                setActiveTab("");
                break;
            case "/upload":
                setActiveTab("nav2");
                
                break;
            case "/profile":
                setActiveTab("nav3");
                break;
            case "/activejobs":
                setActiveTab("nav4");
                break;
            default:
                setActiveTab("nav1");
                break;
        }
    }, [location.pathname]);
    const toggleSidebar = () => {
        if (isSidebarOpen === true) {
          setIsSidebarOpen(false);
        } else if (isSidebarOpen === false) {
          setIsSidebarOpen(true);
        }
      };
    useEffect(() => {
        setInterval(() => {
            setCompLoading(true);
        }, 400);
    }, []);

    
    function closeNavPop() {
        setNavPopup("");
    }
    return (
        <>
        <div className={`dash-nav col-flex ${isSidebarOpen===true ? "active": ""} ${Expandedsidebar===true ? "active":""} ${compLoading===false ? "comp-loading": ""}`} onMouseEnter={expandSidebar} onMouseLeave={collapseSidebar}>
                <div className="sidebar-icon" onClick={toggleSidebar}>
                    <img src={SideIcon} alt="sideIcon" />
                </div>
                <div className="student-mask col-flex">
                    <img src={ProfileIcon} alt="professional" className="i" />
                </div>
                <div className="nav-list col-flex">
                    <Link to="/dashboard" className={`nav-link row-flex ${activeTab === "nav1" ? "active" : ""}`} onClick={() => handleTabClick("nav1")}>
                        <img src={DashIcon} alt="" className="icon" />
                        <span>Dashboard</span>
                    </Link>
                    {/* <Link to="/upload" className={`nav-link row-flex ${activeTab === "nav2" ? "active" : ""}`} onClick={() => handleTabClick("nav2")}>
                        <img src={DocsIcon} alt="" className="icon" />
                        <span>Documents</span>
                    </Link>
                    <Link to="/profile" className={`nav-link row-flex ${activeTab === "nav3" ? "active" : ""}`} onClick={() => handleTabClick("nav3")}>
                        <img src={EditProfile} alt="" className="icon" />
                        <span>Edit Profile</span>
                    </Link>
                    <div className={`nav-link row-flex ${activeTab === "nav4" ? "active" : ""}`} onClick={() => jobPageNavigator("nav4")}>
                        <img src={JobsIcon} alt="" className="icon" />
                        <span>Active Jobs</span>
                    </div> */}
                    <Link className={`nav-link row-flex ${activeTab === "nav" ? "" : ""}`} onClick={logout}>
                        <img src={LogoutIcon} alt="" className="icon" />
                        <span>Logout</span>
                    </Link>
                </div>
            </div>
            {navPopup !=="" && (
                <>
                    <div className="f-screenpoppup">
                        <div className="popup-cont">
                            <div className="close" onClick={closeNavPop}>+</div>
                            <h3>{navPopup}</h3>
                            <div className="reminder">
                                <span>You will be logged out in 5 seconds</span>
                            </div>
                            {sessionButton===true && (
                                <>
                                <div className="button-row row-flex">
                                    <div className="profile-button-session" onClick={continueSession}>
                                        Continue Session >
                                    </div>
                                    <div className="profile-button-session" onClick={logout}>
                                        Logout >
                                    </div>

                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </>
            )}
        </>
    );
}

export default Dashnav;
