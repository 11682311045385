import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import LoginSignup from './components/loginsignup/loginSignup';
import Dashboard from './components/dashboard/dashboard';
import MockExam from './components/dashboard/mock-interview/mock-interview';
import DocumentUpload from './components/dashboard/documentManager/DocumentUpload'; 
import Profile from './components/profile/userProfile';
import FileList from './components/dashboard/documentManager/fileList';

import { AuthProvider } from './authContext';
import ActiveJobs from './components/dashboard/documentManager/JobComponents/activeJobs';

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <Router>
          <Routes>
            <Route path='/' element={<LoginSignup/>}></Route>
            <Route path='/dashboard' element={<Dashboard/>}></Route>
            <Route path='/dashboard/recording' element={<MockExam/>}></Route>
            <Route path='/upload' element={<DocumentUpload/>}></Route>
            <Route path='/filelist' element={<FileList/>}></Route>
            <Route path='/profile' element={<Profile/>}></Route> 
            <Route path='/activeJobs' element={<ActiveJobs/>}></Route> 
          </Routes>
        </Router>
      </AuthProvider>
    </div>
  );
}

export default App;
