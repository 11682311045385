import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import "../dashboard/style.ver0.2.css";
import "../dashboard/documentManager/docStyle.ver0.1.css";
import "./profile.ver0.1.css"
import { useAuth } from "../../authContext";
import Dashnav from "../sidebar/dashnav";

import ProfileBg from "./profileBGver01.png";
import UpIcon from "./upload.png"

const UserProfile = () => {
    const navigate = useNavigate();
    const { isLoggedIn, user, sessionToken } = useAuth();
    const [profileData, setProfileData] = useState({
        first_name: "",
        last_name: "",
        username: "",
        email: "",
        password: "",
        country: "",
        id: "",
        skills: "",
        gender: "",
        mobile_no: "",
        country_code: ""
    });
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [popupActive, setPopupActive] = useState("");

    const username = user ? `${user.email.charAt(0).toUpperCase()}${user.email.split('@')[0].slice(1)} Profile` : "Your Profile";

    useEffect(() => {
        const timer = setTimeout(() => {
            if (isLoggedIn === false) {
                navigate("/");
            }
            console.log(isLoggedIn);
        }, 2000); 
        return () => clearTimeout(timer);
    }, [isLoggedIn, navigate]);

    const apiEndpoint = 'https://us-east1-foursssolutions.cloudfunctions.net/connect4sphere_database_api';

    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const queryObj = {
                    query: `SELECT id, first_name, last_name, username, email, password, country, skills, gender, created_at, storage_path, mobile_no, country_code FROM public.mockinterviewportal_users WHERE email='${user.email}'`
                };

                const response = await fetch(apiEndpoint, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(queryObj)
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }

                const data = await response.json();
                console.log(data);

                if (data.length > 0) {
                    setProfileData(data[0]);
                }
                
                setLoading(false);
            } catch (error) {
                setError(error.message);
                setLoading(false);
            }
        };

        if (sessionToken && user) {
            fetchProfileData();
        }
    }, [sessionToken, user]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setProfileData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Check if any field is empty
        if (!profileData.first_name || !profileData.last_name || !profileData.username || !profileData.email || !profileData.country || !profileData.skills || !profileData.gender || !profileData.mobile_no) {
            alert("Please fill out all fields.");
            return;
        }
        try {
            const updateObj = {
                query: `UPDATE public.mockinterviewportal_users SET first_name='${profileData.first_name}', last_name='${profileData.last_name}',username='${profileData.username}', email='${profileData.email}', country='${profileData.country}', skills='${profileData.skills}', gender='${profileData.gender}', mobile_no='${profileData.mobile_no}', country_code='${profileData.country_code}' WHERE email='${user.email}'`
            };

            const response = await fetch(apiEndpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(updateObj)
            });

            if (!response.ok) {
                throw new Error('Failed to update data');
            }

            setPopupActive('Your profile has been updated successfully!<br><br>Thank you for taking the time to update your profile.');
        } catch (error) {
            setError(error.message);
        }
    };

    if (loading) return <div>Loading...</div>;
    if (error) { console.log("Error:" + error)}

    function closePopup() {
        setPopupActive("");
    }

    return (
        <>
            <div className="main-dash row-flex">
                <Dashnav />
                <div className="content profile col-flex">
                    <div className="docs-tophead row-flex">
                        <div className="head-col col-flex">
                            <h2>{username}</h2>
                        </div>
                        <div className="img-col col-flex">
                            <img src={ProfileBg} alt="bg" className="bgImg" />
                        </div>
                    </div>
                    <div className="profile-container col-flex">
                        <div className="prof-thumb row-flex">
                            <div className="thumb">
                                <img src="https://static.vecteezy.com/system/resources/previews/005/544/718/non_2x/profile-icon-design-free-vector.jpg" alt="profile-thumb" className="thumb-img" />
                                <div className="up-icon">
                                    <img src={UpIcon} alt="" />
                                </div>
                                <input type="file" className="fileupload-input" aria-hidden="true" />
                            </div>
                            <div className="prof-info col-flex">
                                <h3>{user ? `${user.email.charAt(0).toUpperCase()}${user.email.split('@')[0].slice(1)}` : "Your Name"}</h3>
                                <p>{user ? user.email : "Your Email"}</p>
                            </div>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="profile-inputs row-flex">
                                <div className="profile-edit col-flex">
                                    <div className="input col-flex"><span className="head">First Name <span className="mandatory">*</span></span>
                                        <input
                                            type="text"
                                            name="first_name"
                                            placeholder="Your first name"
                                            className="log-in-input"
                                            value={profileData.first_name}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="input col-flex"><span className="head">Email ID <span className="mandatory">*</span></span>
                                        <input
                                            type="text"
                                            name="email"
                                            placeholder="Your email"
                                            className="log-in-input"
                                            value={profileData.email}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="input col-flex"><span className="head">Skills <span className="mandatory">*</span></span>
                                        <input
                                            type="text"
                                            name="skills"
                                            placeholder="Your skills"
                                            className="log-in-input"
                                            value={profileData.skills || ""}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="input col-flex"><span className="head">Gender <span className="mandatory">*</span></span>
                                        <select
                                            name="gender"
                                            className="log-in-input"
                                            value={profileData.gender || ""}
                                            onChange={handleInputChange}
                                        >
                                            <option value="">Select Gender</option>
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                            <option value="Other">Other</option>
                                        </select>
                                    </div>
                                    <button type="submit" className="login-button">Submit</button>
                                </div>
                                <div className="profile-edit col-flex">
                                    <div className="input col-flex"><span className="head">Last Name <span className="mandatory">*</span></span>
                                        <input
                                            type="text"
                                            name="last_name"
                                            placeholder="Your last name"
                                            className="log-in-input"
                                            value={profileData.last_name}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="input col-flex"><span className="head">Mobile no <span className="mandatory">*</span></span>
                                        <div className="row-flex">
                                            <input
                                                    type="text" // Changed to "text" for better control over input
                                                    name="country_code"
                                                    placeholder="Country Code"
                                                    className="log-in-input ctcode"
                                                    value={profileData.country_code || ""}
                                                    maxLength="5" // Set max length to 10 digits
                                                    onChange={handleInputChange}
                                            />
                                            <input
                                                    type="text" // Changed to "text" for better control over input
                                                    name="mobile_no"
                                                    placeholder="Your contact"
                                                    className="log-in-input"
                                                    value={profileData.mobile_no || ""}
                                                    maxLength="12" // Set max length to 10 digits
                                                    onChange={handleInputChange}
                                                    onKeyPress={(e) => {
                                                        // Prevent non-numeric input
                                                        if (!/[0-9]/.test(e.key)) {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                            />
                                        </div>
                                    </div>
                                    <div className="input col-flex"><span className="head">Country <span className="mandatory">*</span></span>
                                        <div className="row-flex">
                                            <input
                                                type="text"
                                                name="country"
                                                placeholder="Your country"
                                                className="log-in-input"
                                                value={profileData.country}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                {popupActive !=="" && (
                <>
                    <div className="f-screenpoppup">
                        <div className="popup-cont">
                            <div className="close" onClick={closePopup}>+</div>
                            <h3 dangerouslySetInnerHTML={{ __html: popupActive}}></h3>
                        </div>
                    </div>
                </>
                )}
            </div>
        </>
    );
}

export default UserProfile;
